import React from "react";
import '../assets/css/templates/news-template.css';
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header, { CurrentPageType } from "../components/Header";
import { ContextWithNewsItemProps } from "../types/newsItem";
import { Link } from "gatsby";
import ReactGA from 'react-ga';

const NewsTemplate: React.FC<ContextWithNewsItemProps> = ( { pageContext: {id, title, timestamp, mainImageName } }) => {
  const data = require(`../data/newsDataContent/${id}.html`).default;
  
   React.useEffect(() => {
  		ReactGA.initialize('UA-88504612-1');
  		ReactGA.pageview(`/news/${id}`);
   }, []);
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Pixel Works Software: ${title} - News`}</title>
        <meta name="description" content={`${title} - Latest news and updates from Pixel Works Software about mobile apps and game solvers.`} />
        <meta name="keywords" content={`${title}, news, pixel works software, mobile apps, game updates`} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.pixelworkssoftware.com/news/${id}`} />
        <meta property="og:title" content={`Pixel Works Software: ${title} - News`} />
        <meta property="og:description" content={`${title} - Latest news and updates from Pixel Works Software about mobile apps and game solvers.`} />
        <meta property="og:image" content={`https://www.pixelworkssoftware.com/static/${mainImageName}`} />
        <meta property="article:published_time" content={timestamp} />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`https://www.pixelworkssoftware.com/news/${id}`} />
        <meta name="twitter:title" content={`Pixel Works Software: ${title} - News`} />
        <meta name="twitter:description" content={`${title} - Latest news and updates from Pixel Works Software about mobile apps and game solvers.`} />
        <meta name="twitter:image" content={`https://www.pixelworkssoftware.com/static/${mainImageName}`} />
        
        {/* Canonical URL */}
        <link rel="canonical" href={`https://www.pixelworkssoftware.com/news/${id}`} />
      </Helmet>
      <div className="gradient"></div>
      <Header currentPage={CurrentPageType.NEWS} />
      <div className="gradient"></div>
      <main className="news-template__container">
        <Link className="apps-template__back-button" to="/news">⇦ BACK</Link>
        <div className="news-template__top">
          <h1 className="news-template__name">{title}</h1>
          <p className="news-item__timestamp">{timestamp}</p>
        </div>
        <div className="news-template__description-container">
          <img className="news-item__main-image" src={require(`../assets/images/${mainImageName}`).default} alt={`${title} - featured image`}/>
          <div className="news-template__description-content" dangerouslySetInnerHTML={{__html: data}}></div>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default NewsTemplate;
